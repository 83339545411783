import {
    ApiReactiveService,
    type ApiServiceUseFetchOptions
} from '@composable-api/service/api.reactive-service'
import { CategoryModel } from '../models/category.model'
import type { ApiModel } from '@composable-api/api.model'
import type { ApiServiceFetchOptions } from '@composable-api/service/api.service'

class CategoriesApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }

    get(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useCategoriesApiService() {
    return new CategoriesApiReactiveService({
        endpoint: '/categories',
    }, CategoryModel)
}
